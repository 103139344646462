/* Reset some default styles */
body, h1, h2, h3, p, a {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', Arial, sans-serif;
}

/* Style for the entire app */
.App {
  background-color: #000000; /* Black background */
  color: #ffffff; /* White text */
}

/* Header styles */
.App-header {
  background-color: #1c1c1c; /* Very dark grey for header */
  color: #ffffff; /* White text */
  height: 80px;
  padding: 20px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.App-header h1 {
  margin-bottom: 20px;
}

nav a {
  text-decoration: none;
  margin-right: 20px;
  font-weight: bold;
}

nav a:hover {
  color: #ffffff; /* Keep white text on hover */
  opacity: 0.7; /* Add opacity for hover state */
}

/* Main content styles */
main {
  padding-top: 100px; /* To prevent content from hiding behind the fixed header */
}

h2 {
  padding: 20px 0 20px 0;
}

section {
  padding: 50px;
  border-bottom: 1px solid #ffffff; /* White separator */
  scroll-margin-top: 100px; /* Offset for fixed header */
}

p {
  padding: 10px 0 10px 0;
}

/* Don't change the color of links */
a, a:visited, a:hover, a:active {
  color: inherit;
}

/* Overview section styles */
#overview {
  color: #ffffff; /* White text */
}

/* Flex Divs */
.flexDiv {
  display: flex;
  gap: 40px;
  justify-content: center;
}

/* Member and Company grid */
.member-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.member {
  background-color: #fffdf3; /* Light cream background for member cards */
  color: #000000; /* Black text */
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  width: 240px; /* Fixed width for grid items */
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2); /* Add shadow for depth */
}

.member img {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 10px;
}

.member-small {
  background-color: #fffdf3; /* Light cream background for member cards */
  color: #000000; /* Black text */
  padding: 8px; /* Further reduced padding */
  border-radius: 4px; /* Smaller border radius */
  text-align: center;
  width: 120px; /* Significantly reduced width for tiny cards */
  box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.2); /* Smaller shadow for depth */
  font-size: 0.8em; /* Reduced font size */
}

.member-small img {
  width: 70%; /* Even smaller image relative to card size */
  border-radius: 50%;
  margin-bottom: 6px; /* Further reduced margin */
}

/* Contact styles */
#contact {
  background-color: #000000; /* Black background */
  color: #ffffff; /* White text */
}

#contact a {
  color: #ffffff; /* White text for links */
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .member-grid {
    flex-direction: column;
    align-items: center;
  }

  nav {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  nav a {
    margin-bottom: 10px;
  }
}
